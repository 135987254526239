<template>
    <div  class="biography-item flex-container"  v-on:keydown.enter="updateBiographyName">
        <input type="text" v-model="name"  :placeholder="$t('biographyNamePlaceHolder')"/>
        <template v-if="nameChanged">
            <img :src="cancelIcon" @click="undoNameChanges" class="cancel-icon"/>
            <img :src="checkIcon" @click="updateBiographyName" class="check-icon"/>
        </template>
        <button class="btn secondary" @click="editBiography">{{$t('edit')}}</button>
        <img :src="deleteIcon" class="delete-icon" @click="showModal = true"/>
        <modal v-if="showModal" :click-handler="removeBiography"
            :text-button="$t('delete')"  @close="showModal = false">
            <div class="delete-message row">
                <span>{{$t('deleteConfirmationMessage',{'0': $t('biography')})}}</span>
            </div>
        </modal>
    </div>
</template>
<script>
import Modal from '@/common/Modal.vue'
import BackOfficeComponentListItemMixin from '@/components/BackOffice/Mixins/BackOfficeComponentListItemMixin.js'
export default {
    name: 'BiographyComponentItem', 
    mixins:[BackOfficeComponentListItemMixin], 
    components:{
        Modal
    },
    data: function(){
        return{
            showModal: false
        }
    },
    methods:{
        removeBiography(){
            this.showModal = false
            this.$store.dispatch('removeBiography', this.component)
        }, 
        updateBiographyName(){
            this.$store.dispatch('updateBiographyName', this.component)
                .then(()=>{
                    this.orignalName[this.locale] = this.name
                })
        },
        editBiography(){
            this.$router.push({name: 'BiographyBuilder', params:{
                biographyId: this.component.id,
                unitId: this.$route.params.unitId
            }} )
        }
    }
}
</script>
<style lang="less" scoped>

.biography-item{
    position: relative;
    min-height: 56px;
    margin-top: 8px;
    width: 100%;

    align-content: center;

    >span{
        color: #0A1554;
        font-family: 'Montserrat', sans-serif;
        font-size: 19.64px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 24px;
        text-transform: uppercase;
    }
    span, input, img{
        align-self: center;
    }
    input:nth-child(1){
        width: 342px;
        border: 1px solid #CFCFCF;
        background-color: #FFFFFF;                
        height: 40px;
        padding-top: 12px;
        padding-left: 24px;
        padding-right: 60px;
        text-overflow: ellipsis;
        padding-bottom: 12px;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
        box-sizing: border-box;
        color: #000;
        font-family: 'Roboto', sans-serif;
    }
    img{
        margin-left: 21px;
        cursor: pointer;
    }

    .cancel-icon{
        width: 16px;
        height: 16px;
        position: absolute;
        right: 168px;  
    }

    .check-icon{
        width: 22px;
        height: 18px;
        position: absolute;
        right: 136px;
    }
}

.biography-item{ 
    .btn{
        width: 65px;
        align-self: center;
        height: 32px;
        min-width: 65px;
        width: auto;
        margin-left: 16px;
    }
}

</style>
