<template>
    <div  class="lesson-item flex-container" :style="{order: component.number}"  v-on:keydown.enter="updateLessonName">
        <span>{{component.number}}</span> 
        <input type="text" v-model="name" :disabled="isOverviewItem"  :placeholder="$t('lessonTitlePlaceHolder')"/>
        <template v-if="nameChanged && !isOverviewItem">
            <img :src="cancelIcon" @click="undoNameChanges" class="cancel-icon"/>
            <img :src="checkIcon" @click="updateLessonName" class="check-icon"/>
        </template>
        <button class="btn secondary" @click="editLesson">{{$t('edit')}}</button>
        <img :src="deleteIcon" class="delete-icon" @click="showModal = true" v-if="!isOverviewItem"/>
        <modal v-if="showModal" :click-handler="removeLesson"
            :text-button="$t('delete')"  @close="showModal = false">
            <div class="delete-message row">
                <span>{{$t('deleteConfirmationMessage',{'0': $t('lesson')})}}</span>
            </div>
        </modal>
    </div>
</template>
<script>
import Modal from '@/common/Modal.vue'
import BackOfficeComponentListItemMixin from '@/components/BackOffice/Mixins/BackOfficeComponentListItemMixin.js'
export default {
    name: 'LessonBuilderItem', 
    mixins:[BackOfficeComponentListItemMixin], 
    components:{
        Modal
    },
    data(){
        return{
            showModal: false
        }
    },
    computed:{
        isOverviewItem(){
            return this.component.number > 0 ? false :true
        }, 
    },
    methods:{
        removeLesson(){
            this.showModal = false
            this.$store.dispatch('removeLesson', this.component)
        }, 
        updateLessonName(){
            this.$store.dispatch('updateLessonName', this.component)
                .then(()=>{
                    this.orignalName[this.locale] = this.name
                })
        },
        editLesson(){
            let route = this.isOverviewItem
            ?{name: 'OverviewBuilder', params:{
                unitId: this.unitId, 
            }}
            :{name: 'LessonBuilder', params:{
                unitId: this.unitId, 
                lessonId: this.component.id
            }} 
            
            this.$router.push(route)
        }
    }
}
</script>
<style lang="less" scoped>


.lesson-item{
    position: relative;
    min-height: 56px;
    margin-top: 8px;
    width: 100%;
    align-content: center;
    >span{
        color: #0A1554;
        font-family: 'Montserrat', sans-serif;
        font-size: 19.64px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 24px;
        text-transform: uppercase;
    }
    span, input, img{
        align-self: center;
    }
    >span:first-child{
        height: 24px;
        width: 10px;
        width: 22px;
        margin-right: 18px;
    }
    input:nth-child(2){
        width: 305px;
        border: 1px solid #CFCFCF;
        background-color: #FFFFFF;                
        height: 40px;
        padding-top: 12px;
        padding-left: 24px;
        padding-right: 60px;
        text-overflow: ellipsis;
        padding-bottom: 12px;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
        box-sizing: border-box;
        color: #000;
        font-family: 'Roboto', sans-serif;
        
    }
    img{
        margin-left: 21px;
        cursor: pointer;
    }

    .cancel-icon{
        width: 16px;
        height: 16px;
        position: absolute;
        right: 168px;  
    }

    .check-icon{
        width: 22px;
        height: 18px;
        position: absolute;
        right: 136px;
    }
}


.btn{
    align-self: center;
	height: 32px;
    min-width: 65px;
    margin-left: 16px;
}
</style>
